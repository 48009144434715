<template>
  <div class="data-row">
    <div class="item prod">
      <div class="productimg">
        <img :src="item.cover" alt="" />
      </div>
      <div class="productname">
        {{ item.name }}
      </div>
    </div>
    <div class="item qt">x {{ item.quantity }}</div>
    <div class="item to">$ {{ item.subtotal }}</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  }
};
</script>
