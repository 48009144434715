import request from '@/utils/request';

//網站訂單結帳
export function checkoutOrder(no) {
  return request({
    url: 'checkout',
    method: 'post',
    data: {
      no
    }
  });
}

//開團向網站下訂單
export function createOrder({
  group_buying_order_id,
  order_contact,
  order_invoice
}) {
  return request({
    url: `group_buying_orders/${group_buying_order_id}/initiator_orders`,
    method: 'post',
    data: {
      order_contact,
      order_invoice
    }
  });
}

//向網站下訂訂單
export function fetchOrders({ page, per_page }) {
  return request({
    url: 'orders',
    method: 'get',
    data: {
      page,
      per_page
    }
  });
}

//變更訂單已付款
export function setOrderPaid(orderID, { bankno }) {
  return request({
    url: `orders/${orderID}/paid`,
    method: 'patch',
    data: {
      bankno
    }
  });
}

export default {
  createOrder,
  fetchOrders,
  setOrderPaid
};
