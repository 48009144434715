<template>
  <div class="main-content">
    <div class="content-block">
      <h2 class="lg-title nb">
        <div class="title-word">訂單記錄</div>
      </h2>
      <div class="container">
        <div class="data-container detail-list record-list">
          <div class="data-head">
            <div class="data-row">
              <div class="item date">訂單日期</div>
              <div class="item prcing">付款金額</div>
              <div class="item p-status">付款狀態</div>
              <div class="item ship-status">出貨狀態</div>
              <div class="item edit"></div>
            </div>
          </div>
          <div class="data-body">
            <Order
              v-for="order in dataList"
              :key="order.id"
              :order="order"
              @order-change="refreshData"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchOrders } from '@/api/order';
import PaginateMixin from '@/mixins/paginate';
import Order from '@/components/OrderList/Order';

export default {
  mixins: [PaginateMixin],
  components: {
    Order
  },
  data() {
    return {
      api: fetchOrders
    };
  }
};
</script>
