<template>
  <div class="data-row" :class="{ active: toggle }">
    <div class="item date">{{ order.created_at }}</div>
    <div class="item prcing">$ {{ order.total_price_text }}</div>
    <div class="item p-status" @click="changePaymentStatus">
      <div class="st" :class="{ done: isPaid }">
        {{ order.payment_status_text }}
      </div>
    </div>
    <div class="item ship-status">
      <div class="st" :class="{ done: isShipped }">
        {{ order.shipping_status_text }}
      </div>
    </div>
    <div class="item edit">
      <div
        class="edit-btn"
        :class="{ active: toggle }"
        @click="clickToggle"
      ></div>
    </div>
    <div class="p-row-wrap">
      <div class="row-head">
        <div class="data-row">
          <div class="item prod">商品</div>
          <div class="item qt">數量</div>
          <div class="item to">總計</div>
        </div>
      </div>
      <div class="row-content">
        <OrderItem v-for="item in order.details" :key="item.id" :item="item" />
      </div>
    </div>

    <BankCodeInputModal
      ref="bankCodeModal"
      :bankno.sync="bankno"
      @confirm="setPaid"
    />
  </div>
</template>

<script>
import OrderItem from '@/components/OrderList/OrderItem';
import { setOrderPaid } from '@/api/order';
import BankCodeInputModal from '@/components/Modals/BankCodeInputModal';

export default {
  components: {
    OrderItem,
    BankCodeInputModal
  },
  data() {
    return {
      toggle: false,
      bankno: null
    };
  },
  props: {
    order: {
      required: true,
      type: Object
    }
  },
  computed: {
    isShipped() {
      return this.order?.shipping_status > 1;
    },
    isPaid() {
      return this.order?.payment_status > 1;
    }
  },
  methods: {
    clickToggle() {
      this.toggle = !this.toggle;
    },
    setPaid() {
      if (this.order?.payment_status == 1) {
        setOrderPaid(this.order.id, { bankno: this.bankno }).then(() => {
          this.$refs.bankCodeModal.hideModal();
          this.$emit('order-change');
        });
      }
    },
    changePaymentStatus() {
      if (this.order?.payment_status == 1) {
        this.$refs['bankCodeModal'].showModal();
      }
    }
  }
};
</script>
